<script lang="ts" setup>
import { MediaImageFragment } from '~/api/graphql/generated'

const props = defineProps<{
  data: {
    title: string
    description: string
    image?: MediaImageFragment
  }
  large?: boolean
}>()

const classImage = computed(() =>
  props.large ? 'image large t-10 t-o-1 tl-9 tl-o-0 ds-o-1' : `image t-10 t-o-1 tl-7 tl-o-0 ds-o-1`,
)

const classInfo = computed(() =>
  props.large ? 'info large t-6 t-o-1 tl-5 tl-o-0 ds-4' : 'info t-6 t-o-1 tl-5 tl-o-0 ds-4',
)
</script>

<template>
  <div :class="classImage">
    <Image v-if="data.image" v-animate="{ animation: 'vertical-reveal' }" :entity="data.image" type="teaser-single" />
  </div>

  <div v-animate="{ animation: 'vertical-reveal' }" :class="classInfo">
    <h2 v-animate="{ animation: 'vertical-reveal' }" class="title">{{ data.title }}</h2>
    <TextSpecial
      v-if="data.description"
      v-animate="{ animation: 'vertical-reveal' }"
      class="description text-small"
      :text="data.description"
    />
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.image {
  @include fluid(--height, 220px, 500px);
  &.large {
    @include fluid(--height, 250px, 580px);
    .dp-image {
      margin-left: calc(50% - 50vw);
      @include breakpoint(t) {
        margin-left: 0;
      }
    }
  }
  .dp-image {
    max-width: none;
    width: calc(50% + 50vw);
    height: var(--height);

    @include breakpoint(t) {
      max-width: 100%;
      width: 100%;
    }
  }
}

.info {
  --margin: -48px;
  --padding: 24px;
  grid-column-start: 1;

  min-height: 48px;
  padding-right: var(--padding);
  padding-top: 24px;
  background-color: var(--c-white);

  margin-top: var(--margin);

  @include breakpoint(tl) {
    --margin: -100px;
    --padding: 40px;

    min-height: 156px;
    padding-top: 32px;
  }

  @include breakpoint(ds) {
    --margin: -156px;
    --padding: 64px;
  }

  &.large {
    padding-right: 0;
    padding-left: var(--padding);
    @include breakpoint(t) {
      --offset: 5;
    }
    @include breakpoint(ds) {
      --offset: 7;
    }
  }

  .title {
    @include fluid(margin-bottom, 8px, 12px);
  }

  .description {
    margin-bottom: 18px;
  }
}
</style>
